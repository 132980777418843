<template>
  <v-card width="100%">
    <v-row>
      <v-col cols="12" sm="12" md="3">
        <v-card-title>Ranking anual - 2021 </v-card-title>
      </v-col>
      <v-col cols="12" sm="12" md="4">
        <v-select
          return-object
          :items="grupos_arr"
          item-text="title"
          item-value="id"
          label="Seleciona el grupo"
          v-model="id_grupo_selected"
          @change="change_id_state()"
        ></v-select>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col cols="12">
        <v-card v-if="loaded" elevation="7">
          <v-simple-table dense fixed-header height="76vh">
            <template v-slot:default>
              <thead>
                <tr style="height:120px">
                  <th>#</th>
                  <th class="text-left">
                    Apellidos y nombres
                  </th>
                  <th class="text-left">
                    Puntaje total
                  </th>
                  <th class="text-left">
                    Estado
                  </th>
                  <th v-for="item in alumnos[0].promedios" :key="item.area">
                    <p class="vertical">{{ item.area }}</p>
                  </th>
                  <th>Detalle</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, index) in alumnos" :key="item.nombres">
                  <td>{{ index + 1 }}</td>
                  <td>{{ item.nombres }}</td>
                  <td>{{ item.suma }}</td>
                  <td>
                    <v-chip
                      dark
                      :color="
                        item.estado == 'Aprobado'
                          ? '#00AB8E'
                          : item.estado == 'Desaprobado'
                          ? '#AB090D'
                          : '#F79919'
                      "
                      x-small
                      outlined
                    >
                      {{ item.estado }}
                    </v-chip>
                  </td>
                  <td v-for="nota in item.promedios" :key="nota.area">
                    <div v-if="nota.promedio_area >= 10.5">
                      {{ nota.promedio_area }}
                    </div>
                    <div v-else style="color:red;">
                      {{ nota.promedio_area }}
                    </div>
                  </td>
                  <td>
                    <v-btn
                      icon
                      color="primary"
                      @click="go_detail(item.idalumno)"
                    >
                      <v-icon>mdi-eye</v-icon>
                    </v-btn>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card>
        <v-progress-linear
          v-else
          color="deep-purple accent-4"
          indeterminate
          rounded
          height="6"
        ></v-progress-linear>
      </v-col>
    </v-row>
  </v-card>
</template>
<script>
import axios from "axios";
import api from "@/api.config.js";
import { mapState, mapMutations } from "vuex";
export default {
  data: () => ({
    loaded: false,
    expanded: [],
    singleExpand: false,
    alumnosHeaders: [
      {
        text: "Apellidos y nombres",
        align: "start",
        sortable: true,
        value: "nombres",
      },
      { text: "Puntaje total", value: "suma" },
      { text: "Estado", value: "estado" },
      { text: "Detalles", value: "data-table-expand" },
    ],
    desserts: [],
    alumnos: [],
    grupos: ["Primaria Primero A"],
    grupos_arr: [],
    id_grupo_selected: null,
  }),
  computed: {
    ...mapState(["grupo_selected"]),
  },
  methods: {
    ...mapMutations(["set_grupo_selected"]),
    change_id_state() {
      this.loaded = false;
      this.set_grupo_selected(this.id_grupo_selected.id);
      this.get_data();
    },
    get_config() {
      let tok = JSON.parse(localStorage.getItem("token-user"));
      let token = tok["token-user"];
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Token " + token,
        },
      };
      return config;
    },
    get_grupos() {
      axios
        .get(api.API_PATH + "/api/grupos-detalle/", this.get_config())
        .then((r) => {
          this.grupos_arr = [];
          let datos = r.data;
          datos.forEach((e) => {
            this.grupos_arr.push({
              id: e.id,
              title:
                e.nivel.descripcion +
                " " +
                e.grado.descripcion +
                " " +
                e.seccion.descripcion,
            });
          });

          let id_teacher = JSON.parse(localStorage.getItem("token-user"));
          let res;
          if (id_teacher.id == 17) {
            res = this.grupos_arr.find((e) => e.id == 1);
            this.grupos_arr = this.grupos_arr.filter((e) => e.id == 1);
          } else if (id_teacher.id == 9) {
            res = this.grupos_arr.find((e) => e.id == 2);
            this.grupos_arr = this.grupos_arr.filter((e) => e.id == 2);
          } else if (id_teacher.id == 4) {
            res = this.grupos_arr.find((e) => e.id == 3);
            this.grupos_arr = this.grupos_arr.filter((e) => e.id == 3);
          } else if (id_teacher.id == 8) {
            res = this.grupos_arr.find((e) => e.id == 4);
            this.grupos_arr = this.grupos_arr.filter((e) => e.id == 4);
          } else if (id_teacher.id == 25) {
            res = this.grupos_arr.find((e) => e.id == 5);
            this.grupos_arr = this.grupos_arr.filter((e) => e.id == 5);
          } else if (id_teacher.id == 23) {
            res = this.grupos_arr.find((e) => e.id == 6);
            this.grupos_arr = this.grupos_arr.filter((e) => e.id == 6);
          } else if (id_teacher.id == 14) {
            res = this.grupos_arr.find((e) => e.id == 7);
            this.grupos_arr = this.grupos_arr.filter((e) => e.id == 7);
          } else if (id_teacher.id == 18) {
            res = this.grupos_arr.find((e) => e.id == 8);
            this.grupos_arr = this.grupos_arr.filter((e) => e.id == 8);
          } else if (id_teacher.id == 11) {
            res = this.grupos_arr.find((e) => e.id == 9);
            this.grupos_arr = this.grupos_arr.filter((e) => e.id == 9);
          } else if (id_teacher.id == 6) {
            res = this.grupos_arr.find((e) => e.id == 10);
            this.grupos_arr = this.grupos_arr.filter((e) => e.id == 10);
          } else if (id_teacher.id == 16) {
            res = this.grupos_arr.find((e) => e.id == 11);
            this.grupos_arr = this.grupos_arr.filter((e) => e.id == 11);
          } else if (id_teacher.id == 5) {
            res = this.grupos_arr.find((e) => e.id == 12);
            this.grupos_arr = this.grupos_arr.filter((e) => e.id == 12);
          } else if (id_teacher.id == 21) {
            res = this.grupos_arr.find((e) => e.id == 13);
            this.grupos_arr = this.grupos_arr.filter((e) => e.id == 13);
          } else if (id_teacher.id == 19) {
            res = this.grupos_arr.find((e) => e.id == 14);
            this.grupos_arr = this.grupos_arr.filter((e) => e.id == 14);
          } else if (id_teacher.id == 15) {
            res = this.grupos_arr.find((e) => e.id == 15);
            this.grupos_arr = this.grupos_arr.filter((e) => e.id == 15);
          } else if (id_teacher.id == 1) {
            res = this.grupos_arr.find((e) => e.id == 16);
            this.grupos_arr = this.grupos_arr.filter((e) => e.id == 16);
          } else if (id_teacher.id == 13) {
            res = this.grupos_arr.find((e) => e.id == 19);
            this.grupos_arr = this.grupos_arr.filter((e) => e.id == 19);
          } else {
            res = this.grupos_arr.find((e) => e.id == this.grupo_selected);
          }
          if (res) {
            this.id_grupo_selected = res;
            //console.log(res);
            this.set_grupo_selected(this.id_grupo_selected.id);
          }

          this.get_data();
        })
        .catch((e) => {
          console.log(e);
        });
    },
    get_data() {
      this.alumnos = [];
      axios
        .get(
          api.API_PATH +
            "/api/promedios-por-grupo/" +
            this.grupo_selected +
            "/",
          this.get_config()
        )
        .then((data) => {
          this.format_data(data.data);
        })
        .catch((e) => {
          console.log(e);
        });
    },
    format_data(data) {
      let aux = [...data];
      this.alumnos = [];

      for (let item of aux) {
        let cont = 0;
        item.promedios.forEach((el) => {
          if (el.promedio_area < 10.5) {
            cont++;
          }
        });
        item.promedios.sort((a, b) => a.id - b.id);

        this.alumnos.push({
          idalumno: item.id,
          nombres: item.alumno,
          suma: item.suma,
          estado:
            cont >= 4
              ? "Desaprobado"
              : cont >= 1 && cont <= 3
              ? "Por recuperar"
              : "Aprobado",
          promedios: item.promedios,
        });
      }
      this.alumnos.sort((a, b) => b.suma - a.suma);

      this.loaded = true;
    },
    go_detail(id) {
      this.$router.push({
        name: "NotasPorGrupoDetalle",
        params: { idalumno: id },
      });
    },
  },
  mounted() {
    this.get_grupos();
  },
};
</script>
<style>
.vertical {
  writing-mode: vertical-rl;
  transform: rotate(180deg);
}
.ancho {
  width: fit-content;
}
</style>
